import React, { Component } from 'react';
import 'index.css';
import 'components/Cinemas.scss';
import _ from 'lodash';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import GetApp from '@material-ui/icons/GetApp';
import { ExportToCsv } from 'export-to-csv';

import CinemasList from 'components/CinemasList';
import AddCinema from 'components/AddCinema';

const options = {
    filename:'cinemas',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

class Cinemas extends Component {
    constructor(props){
        super(props);
        this.state={
            openNewCinemaDialog:false,
        }
        this.handleNewCinemaDialogOpen=this.handleNewCinemaDialogOpen.bind(this);
        this.handleNewCinemaDialogClose=this.handleNewCinemaDialogClose.bind(this);
        this.getCsv=this.getCsv.bind(this);
    }
    getCsv(){
        let cinemas=_.cloneDeep(this.props.modele.cinemas);
        cinemas.map((c)=>{
            let latitude=c.latitude;
            let longitude=c.longitude;
            c.latitude=latitude.replace('.',',');
            c.longitude=longitude.replace('.',',');
            return null;
        })
        csvExporter.generateCsv(cinemas);
    }
    handleNewCinemaDialogOpen(){
        this.setState({openNewCinemaDialog:true});
    }
    handleNewCinemaDialogClose(e){
        this.setState({openNewCinemaDialog:false});
    }
    render() {
        let cinemas=this.props.modele.cinemas;
        return <Container maxWidth="lg" className="accueil">
            <Typography variant="h1" component="h1">
                Cinémas &nbsp;
                <Fab color="primary" aria-label="add" onClick={this.handleNewCinemaDialogOpen}>
                    <AddIcon />
                </Fab> <Fab color="primary" aria-label="add" onClick={this.getCsv}>
                    <GetApp />
                </Fab>
            </Typography>
            <AddCinema { ... this.props} open={this.state.openNewCinemaDialog} onClose={this.handleNewCinemaDialogClose}/>
            <CinemasList global cinemas={cinemas} {...this.props}/>
        </Container>;
    }
}
export default Cinemas;
