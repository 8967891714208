import React, { Component } from 'react';
import 'index.css';
import 'components/Accueil.scss';
import _ from 'lodash';
import * as moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Image from 'components/Image';
import Parametres from 'utils/Parametres';
import Utils from 'utils/Utils';
import Pagination from '@material-ui/lab/Pagination';
import CheckIcon from '@material-ui/icons/Check';

class Accueil extends Component {
    constructor(props){
        super(props);
        this.state={
            page:1,
            itemsParPage:6,
            filter:{
                cinema_name:'',
                city_name:'',
            },
        }
        this.handlePageChange=this.handlePageChange.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }
    handlePageChange (event, value) {
        this.setState({page:value});
    };
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        let appState=this.props.appState;
        if (!appState.cinemasFilter) appState.cinemasFilter={};
        appState.cinemasFilter[name]=value;
        this.props.methods.modeleSet(appState);
    }
    render() {
        let nSeance='';
        if (this.props.modele.shows.length<=1) nSeance=this.props.modele.shows.length+' séance';
        else nSeance=this.props.modele.shows.length+' séances';
        let nCinemas='';
        if (this.props.modele.cinemas.length<=1) nCinemas=this.props.modele.shows.length+' cinéma';
        else nCinemas=this.props.modele.cinemas.length+' cinémas';
        let nFilms='';
        if (this.props.modele.films.length<=1) nFilms=this.props.modele.films.length+' film';
        else nFilms=this.props.modele.films.length+' films';
        let futur=_.filter(this.props.modele.shows,(s)=>moment(s.start_time).format('x')>Date.now());
        futur.sort((a,b)=>Utils.compareAsc('start_time',
            {'start_time':moment(a.start_time).format('X')},
            {'start_time':moment(b.start_time).format('X')}
        ));
        let aVenir=_.filter(futur,(s,i)=>
            i>=(this.state.page-1)*this.state.itemsParPage
            && i<(this.state.page)*this.state.itemsParPage
        )
        let nbPages=Math.ceil((futur.length-1)/this.state.itemsParPage);
        return <Container maxWidth="lg" className="accueil">
            <Grid container spacing={3} className="main-grid">
                <Grid item xs={12} lg={4}>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h3" component="h2">
                            {nFilms}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" size="small" onClick={()=>this.props.goTo('/films/')}>Voir tous les films</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h3" component="h2">
                            {nCinemas}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" size="small" onClick={()=>this.props.goTo('/cinemas/')}>Voir tous les cinémas</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h3" component="h2">
                            {nSeance}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" size="small" onClick={()=>this.props.goTo('/seances/')}>Voir toutes les séances</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Typography variant="h2" component="h2">
                Séances à venir
            </Typography>
            <Grid container spacing={3} className="main-grid">
            <Grid item xs={12} md={6}>
                <Pagination count={nbPages} page={this.state.page} onChange={this.handlePageChange} className="pagination-center"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <p className="page-infos">
                {(this.state.page-1)*this.state.itemsParPage+1} - {Math.min(futur.length,(this.state.page)*this.state.itemsParPage)} de {futur.length}
                </p>
            </Grid>
            </Grid>
            <Grid container spacing={3} className="main-grid">
                {aVenir.map((show)=>{
                    let poster='';
                    let posterFile='img/cinema/poster/'+show.content_id+'.jpg';
                    let posterMinFile='img/cinema/poster/min/'+show.content_id+'.jpg?'+this.props.appState.posterHash;
                    let posterSrc=Parametres.ajaxUrl+posterMinFile;
                    let chip='';
                    if (show.active==="1") chip=<div className="poster-chip">
                    <Chip
                        label="Active"
                        variant="outlined"
                        icon={<CheckIcon />}
                        color="primary"
                    />
                    </div>;
                    else chip=<div className="poster-chip">
                    <Chip
                        label="Non active"
                        variant="outlined"
                        color="default"
                    />
                    </div>;
                    if (this.props.modele.posters.indexOf(posterFile)!==-1) poster=<div className="film-poster">
                        <div className="film-poster-inner">
                            <Image shadow src={posterSrc}/>
                        </div>
                    </div>;
                    else poster=<div className="film-poster">
                        <div className="film-poster-inner">
                        </div>
                    </div>;
                    return <Grid key={show.id} item xs={12} md={6} lg={4}>
                        <Card className="relative">
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                {poster}
                                </Grid>
                                <Grid item xs={7}>
                                    <div className="spacer"></div>
                                    <Typography variant="h5" component="h2">
                                        {moment(show.start_time).format('DD/MM/YYYY')}<br />
                                        {moment(show.start_time).format('HH[h]mm')}
                                    </Typography>
                                    <Typography variant="h6" component="h2" noWrap>
                                        {show.content_name}
                                    </Typography>
                                    <Typography variant="h6" component="h3" className='city' noWrap>
                                        {show.cinema_name} <span className="city">/ {show.city_name}</span>
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={()=>this.props.goTo('/seance/'+show.id+'/')}
                                    >
                                    Voir
                                    </Button>
                                </Grid>
                            </Grid>
                            {chip}
                        </Card>
                    </Grid>;
                })}
            </Grid>
            <div className="spacer"></div>
            <Pagination count={nbPages} page={this.state.page} onChange={this.handlePageChange} className="pagination-center"/>
        </Container>;
    }
}
export default Accueil;
