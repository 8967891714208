import React, { Component } from 'react';
import 'index.css';
import 'components/Seances.scss';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import GetApp from '@material-ui/icons/GetApp';
import { ExportToCsv } from 'export-to-csv';

import SeancesList from 'components/SeancesList';
import AddSeance from 'components/AddSeance';

const options = {
    filename:'seances',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

class Seances extends Component {
    constructor(props){
        super(props);
        console.log(this.props.modele.cinemas[0].cinema_id);
        this.state={
            openNewSeanceDialog:false,
        }
        this.handleNewSeanceDialogOpen=this.handleNewSeanceDialogOpen.bind(this);
        this.handleNewSeanceDialogClose=this.handleNewSeanceDialogClose.bind(this);
        this.getCsv=this.getCsv.bind(this);
    }
    getCsv(){
        csvExporter.generateCsv(this.props.modele.shows);
    }
    handleNewSeanceDialogOpen(){
        this.setState({openNewSeanceDialog:true});
    }
    handleNewSeanceDialogClose(e){
        this.setState({openNewSeanceDialog:false});
    }
    render() {
        let shows=this.props.modele.shows;
        return <Container maxWidth="lg" className="accueil">
            <Typography variant="h1" component="h1">
                Séances &nbsp;
                <Fab color="primary" aria-label="add" onClick={this.handleNewSeanceDialogOpen}>
                    <AddIcon />
                </Fab> <Fab color="primary" aria-label="add" onClick={this.getCsv}>
                    <GetApp />
                </Fab>
            </Typography>
            <AddSeance { ... this.props} open={this.state.openNewSeanceDialog} onClose={this.handleNewSeanceDialogClose}/>
            <SeancesList global shows={shows} {...this.props}/>
        </Container>;
    }
}
export default Seances;
