import React, { Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import 'components/Seances.scss';
import Utils from 'utils/Utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

class Seances extends Component {
    constructor(props){
        super(props);
        this.state={
            page:1,
            itemsParPage:10,
            filter:{
                cinema_name:'',
                city_name:'',
            },
        }
        this.handleDelete=this.handleDelete.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handlePageChange=this.handlePageChange.bind(this);
        this.handleClearFilter=this.handleClearFilter.bind(this);
    }
    handlePageChange (event, value) {
        if (this.props.global) {
            this.props.methods.appStateSet({pageSeances:value});
        } else {
            this.setState({page:value});
        }
    };
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        if (this.props.global) {
            let appState=this.props.appState;
            appState.seancesFilter[name]=value;
            this.props.methods.appStateSet(appState);
        } else {
            let filter=this.state.filter;
            filter[name]=value;
            this.setState({filter:filter});
        }
        this.setState({page:1});
    }
    handleDelete(show){
        if(window.confirm('Est-vous sûr de vouloir supprimer cette séance ?')) {
            this.props.methods.ajax({
                action:'del_show',
                show:show,
            });
        }
    }
    handleClearFilter(key){
        if (this.props.global) {
            let appState=this.props.appState;
            appState.seancesFilter[key]='';
            this.props.methods.appStateSet(appState);
        } else {
            let filter=this.state.filter;
            filter[key]='';
            this.setState({filter:filter});
        }
        this.setState({page:1});
    }
    render() {
        let shows=[];
        let filter=this.state.filter;
        if (this.props.global)
            filter=this.props.appState.seancesFilter;
        if (this.props.shows) shows=this.props.shows;
        else shows=this.props.modele.shows;
        let filtered=_.filter(shows,(s)=>{
            let cleanCinemaName, cleanFilterCinemaName,
                cleanCityName, cleanFilterCityName,
                cleanContentName,  cleanFilterContentName,
                cleanStartDate, cleanFilterStartDate;
            let cinemaTest=true;
            let cityTest=true;
            let contentTest=true;
            let startDateTest=true;
            if (filter.cinema_name) {
                cleanCinemaName=Utils.accentsTidy(s.cinema_name).toLowerCase();
                cleanFilterCinemaName=Utils.accentsTidy(filter.cinema_name).toLowerCase();
                cinemaTest=cleanCinemaName.indexOf(cleanFilterCinemaName)>=0;
            }
            if (filter.city_name) {
                cleanCityName=Utils.accentsTidy(s.city_name).toLowerCase();
                cleanFilterCityName=Utils.accentsTidy(filter.city_name).toLowerCase();
                cityTest=cleanCityName.indexOf(cleanFilterCityName)>=0;
            }
            if (filter.content_name) {
                cleanContentName=Utils.accentsTidy(s.content_name).toLowerCase();
                cleanFilterContentName=Utils.accentsTidy(filter.content_name).toLowerCase();
                contentTest=cleanContentName.indexOf(cleanFilterContentName)>=0;
            }
            if (filter.start_time) {
                cleanStartDate=s.start_time;
                cleanFilterStartDate=filter.start_time.split('/').reverse().join('-');
                startDateTest=cleanStartDate.indexOf(cleanFilterStartDate)>=0;
            }
            return cinemaTest && cityTest && contentTest && startDateTest;
        });
        let page=this.state.page;
        if (this.props.global) page=this.props.appState.pageSeances;
        let selection=_.filter(filtered,(s,i)=>
            i>=(page-1)*this.state.itemsParPage
            && i<(page)*this.state.itemsParPage
        )
        let nbPages=Math.ceil((filtered.length-1)/this.state.itemsParPage);
        return <div>
            <Grid container spacing={3} className="main-grid">
            <Grid item xs={12} lg={3}>
            <TextField
                className="text-input"
                label="Cinema"
                name="cinema_name"
                variant="filled"
                InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <Search />
                </InputAdornment>
                ),
                endAdornment: (
                <InputAdornment position="end">
                    <Clear onClick={()=>this.handleClearFilter('cinema_name')}/>
                </InputAdornment>
                ),
                }}
                value={filter.cinema_name}
                onChange={this.handleChange}
            />
            </Grid>
            <Grid item xs={12} lg={3}>
            <TextField
                className="text-input"
                label="Ville"
                name="city_name"
                variant="filled"
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                    endAdornment: (
                    <InputAdornment position="end" className="clickable">
                        <Clear onClick={()=>this.handleClearFilter('city_name')}/>
                    </InputAdornment>
                    ),
                }}
                value={filter.city_name}
                onChange={this.handleChange}
            />
            </Grid>
            <Grid item xs={12} lg={3}>
            <TextField
                className="text-input"
                label="Film"
                name="content_name"
                variant="filled"
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                    endAdornment: (
                    <InputAdornment position="end" className="clickable">
                        <Clear onClick={()=>this.handleClearFilter('content_name')}/>
                    </InputAdornment>
                    ),
                }}
                value={filter.content_name}
                onChange={this.handleChange}
            />
            </Grid>
            <Grid item xs={12} lg={3}>
            <TextField
                className="text-input"
                label="Date"
                name="start_time"
                variant="filled"
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                    endAdornment: (
                    <InputAdornment position="end" className="clickable">
                        <Clear onClick={()=>this.handleClearFilter('start_time')}/>
                    </InputAdornment>
                    ),
                }}
                value={filter.start_time}
                onChange={this.handleChange}
            />
            </Grid>
            </Grid>
            <Grid container spacing={3} className="main-grid">
            <Grid item xs={12} md={6}>
                <Pagination count={nbPages} page={page} onChange={this.handlePageChange} className="pagination-center"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <p className="page-infos">
                {(page-1)*this.state.itemsParPage+1} - {Math.min(filtered.length,(page)*this.state.itemsParPage)} de {filtered.length}
                </p>
            </Grid>
            </Grid>
            <TableContainer component={Paper}>
            <Table className="shows-table" size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>
                    <Grid container spacing={1} className="main-grid">
                        <Grid item xs={12} lg={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="left" noWrap>Active</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="left" noWrap>Date</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="left" noWrap>Cinéma</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="left" noWrap>Film</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden mdDown>
                        <Grid item lg={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <Typography align="right" noWrap>Prix</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" noWrap>Vimeo ID</Typography>

                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" noWrap>Live Vimeo ID</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" noWrap>Tchat ID</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align="right" noWrap>Tchat</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Hidden>
                    </Grid>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {selection.map((s) => (
                <TableRow key={s.id}>
                    <TableCell>
                    <Grid container spacing={1} className="main-grid">
                        <Grid item xs={12} lg={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <IconButton aria-label="edit" size="small" onClick={()=>this.props.goTo('/seance/'+s.id+'/')}>
                                              <EditIcon/>
                                            </IconButton>
                                            <IconButton aria-label="edit" size="small" onClick={()=>this.handleDelete(s)}>
                                              <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align="left" noWrap>{s.active==="1" && <CheckIcon color="primary"/>}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="left" noWrap>{moment(s.start_time).format('DD/MM/YYYY - HH[h]mm')}</Typography>
                                </Grid>
                                <Grid item xs={3} onClick={()=>this.props.goTo('/cinema/'+s.cinema_id+'/')} className="clickable">
                                    <Typography align="left" noWrap>{s.cinema_name} <small>{s.city_name}</small></Typography>
                                </Grid>
                                <Grid item xs={3} onClick={()=>this.props.goTo('/film/'+s.content_id+'/')} className="clickable">
                                    <Typography align="left" noWrap>{s.content_name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden mdDown>
                        <Grid item lg={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <Typography align="right" noWrap>{s.price}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" noWrap>{s.vimeo_id}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" noWrap>{s.live_vimeo_id}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right" noWrap>{s.tchat_id}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography align="right" noWrap>{s.tchat==="1" && <CheckIcon color="primary"/>}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Hidden>
                    </Grid>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <div className="spacer"></div>
        <Pagination count={nbPages} page={page} onChange={this.handlePageChange} className="pagination-center"/>
        </div>;
    }
}
export default Seances;
