import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/DupSeance.scss';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogActions from '@material-ui/core/DialogActions';

class DupSeance extends Component {
    constructor(props){
        super(props);
        this.state={
            newCinemaId: this.props.cinemaId || this.props.modele.cinemas[0].cinema_id,
        }
        this.handleNewCinemaIdChange=this.handleNewCinemaIdChange.bind(this);
        this.handleCreateNewSeance=this.handleCreateNewSeance.bind(this);
    }
    handleNewCinemaIdChange(e,v){
        let cinema_id;
        if (v) {
            cinema_id=v.cinema_id;
            this.setState({
                newCinemaId:cinema_id,
            });
        }
    }
    handleCreateNewSeance(e){
        e.preventDefault();
        this.setState({openNewSeanceDialog:false});
        let cinema=_.find(this.props.modele.cinemas,{cinema_id:this.state.newCinemaId});
        let show=_.cloneDeep(this.props.oldShow);
        show={ ...show, ...cinema};
        this.props.methods.ajax(
            {
                action:'add_show',
                show:show,
            },
            {},
            (res)=>{
                show.id=res.last_id;
                show.show_id=res.last_id;
                this.props.methods.ajax({
                        action:'mod_show',
                        show:show,
                    },
                    {},
                    ()=>this.props.goTo('/seance/'+res.last_id+'/')
                );
                return null;
            }
        );
    }
    render() {
        let newCinema=_.find(this.props.modele.cinemas,{cinema_id:this.state.newCinemaId});
        return <Dialog open={this.props.open} onClose={this.props.onClose}>
            <form onSubmit={this.handleCreateNewSeance}>
            <DialogTitle>Nouvelle séance</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Merci de saisir un cinéma pour cette nouvelle séance.
                </DialogContentText>
                <Autocomplete
                value={newCinema}
                onChange={this.handleNewCinemaIdChange}
                options={this.props.modele.cinemas}
                getOptionLabel={(option) => (option.cinema_name || option.cinema_id) +' / '+ option.city_name }
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} variant="filled" label="Cinéma" className="text-input"/>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                Annuler
                </Button>
                <Button type="submit" color="primary">
                Ajouter
                </Button>
            </DialogActions>
            </form>
            </Dialog>;
    }
}
export default DupSeance;
