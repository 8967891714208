import React, { Component } from 'react';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import * as moment from 'moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//import _ from 'lodash';
import './App.scss';
import axios from 'axios';
import Qs from 'qs';
import Parametres from 'utils/Parametres';
import Utils from 'utils/Utils';
import 'typeface-roboto';
import Leaflet from 'leaflet'

//material
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//components
import Accueil from 'components/Accueil';
import Cinema from 'components/Cinema';
import Seance from 'components/Seance';
import Film from 'components/Film';
import Cinemas from 'components/Cinemas';
import Seances from 'components/Seances';
import Films from 'components/Films';
import Router from 'components/Router';

moment.locale('fr',Utils.momentFr);
axios.defaults.withCredentials = true;
Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'


class App extends Component {
    constructor(props){
        super(props);
        this.state={
            modele:{},
            modeleSrv:{},
            appState:{
                drawerOpen:false,
                posterHash:0,
                pageSeances:1,
                pageCinemas:1,
                cinemasFilter:{
                    cinema_name:'',
                    city_name:'',
                },
                seancesFilter:{
                    cinema_name:'',
                    city_name:'',
                    content_name:'',
                    start_time:'',
                }
            },
            templates:[
                {id:'accueil',component: Accueil },
                {id:'cinema',component: Cinema },
                {id:'seance',component: Seance },
                {id:'film',component: Film },
                {id:'cinemas',component: Cinemas },
                {id:'seances',component: Seances },
                {id:'films',component: Films },
            ],
            routes:[
                {url:'/',template:'accueil'},
                {url:'/cinemas/',template:'cinemas'},
                {url:'/films/',template:'films'},
                {url:'/seances/',template:'seances'},
            ],
            shows:[],
            authorized:false,
            ready:false,
            currentRoute:{url:'/'},
        }
        this.ajax=this.ajax.bind(this);
        this.methods={
            routeAdd:this.routeAdd.bind(this),
            modeleSet:this.modeleSet.bind(this),
            appStateSet:this.appStateSet.bind(this),
            ajax:this.ajax,
        };
        this.base='';
        this.history = createBrowserHistory({basename:this.base});
        this.goTo=this.goTo.bind(this);
        this.handleLogin=this.handleLogin.bind(this);
        this.handleLogout=this.handleLogout.bind(this);
        this.toggleDrawer=this.toggleDrawer.bind(this);
        this.handlePasswordChange=this.handlePasswordChange.bind(this);
        this.handleRouteHasChanged=this.handleRouteHasChanged.bind(this);
    }
    toggleDrawer(state){
        this.methods.appStateSet({drawerOpen:state});
    }
    appStateSet(s){
        let appState=this.state.appState;
        appState={ ...appState, ...s };
        this.setState({appState:appState});
    }
    modeleSet(m){
        let modele=this.state.modele;
        modele={ ...modele, ...m };
        this.setState({modele:modele});
    }
    routeAdd(route){
        let routes=this.state.routes;
        routes.push(route);
        this.setState({routes:routes});
    }
    handleRouteHasChanged(route){
        this.goTop();
        this.setState({currentRoute:route});
    }
    goTop(){
        window.scrollTo(0,0);
    }
    goTo(url){
        console.log(url);
        if ( url.indexOf('http://')>=0 || url.indexOf('https://')>=0)
            window.open(url, '_blank');
        else
            this.history.push(url);
    }
    ajax(o,config,successCb,errorCb){
        if (!config) config={};
        if (!successCb) successCb=()=>null;
        if (!errorCb) errorCb=()=>null;
        let params=Qs.stringify(o);
        if (config.headers && config.headers['Content-Type']==='multipart/form-data') params=o;
        axios.post(Parametres.ajaxUrl + 'ajax.php',params,config).then(
            (res) => {
                if (res.data.authorized) {
                    let modele=this.state.modele;
                    modele.posters=res.data.posters;
                    modele.default_screens=res.data.default_screens;
                    res.data.shows.sort((a,b)=>Utils.compareDesc('start_time',
                        {'start_time':moment(a.start_time).format('X')},
                        {'start_time':moment(b.start_time).format('X')}
                    ));
                    if (!_.isEqual(modele.shows,res.data.shows)) {
                        modele.shows=res.data.shows;
                    }
                    res.data.cinemas.sort((a,b)=>Utils.compareAsc('cinema_name',a,b));
                    if (!_.isEqual(modele.cinemas,res.data.cinemas)) {
                        modele.cinemas=res.data.cinemas;
                    }

                    res.data.films.sort((a,b)=>Utils.compareAsc('content_name',a,b));
                    if (!_.isEqual(modele.films,res.data.films)) {
                        modele.films=res.data.films;
                    }
                    let routes=this.state.routes;
                    modele.cinemas.map((c)=>
                        routes.push({
                            url:'/cinema/'+c.cinema_id+'/',
                            template:'cinema',
                        })
                    );
                    modele.shows.map((s)=>
                        routes.push({
                            url:'/seance/'+s.id+'/',
                            template:'seance',
                        })
                    );
                    modele.films.map((f)=>{
                        if (!f.teaser_id) f.teaser_id='';
                        routes.push({
                            url:'/film/'+f.content_id+'/',
                            template:'film',
                        });
                        return null;
                    });
                    let modeleSrv=_.cloneDeep(modele);
                    this.setState({
                        authorized:true,
                        ready:true,
                        modele:modele,
                        modeleSrv:modeleSrv,
                        routes:routes,
                    },()=>successCb(res.data));
                } else {
                    this.setState({
                        authorized:false,
                        ready:true,
                        selected:[],
                    });
                }
            }
        ).catch(errorCb);
    }
    handlePasswordChange(e){
        let pwd=e.target.value;
        this.setState({pwd:pwd});
    }
    handleLogout(e){
        this.ajax({
            action:'logout',
        });
    }
    handleLogin(e) {
        let pwd=this.state.pwd;
        this.ajax({
            action:'login',
            pwd:pwd,
        });
        e.preventDefault();
    }
    componentDidMount(){
        this.ajax({
            action:'refresh',
        });
        this.interval=setInterval(()=>this.ajax({
            action:'refresh',
        }),5000);
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    render(){
        let app='';
        let router='';
        if (this.state.routes.length>0)
            router=<Router key="router"
            routes={this.state.routes}
            templates={this.state.templates}
            goTo={this.goTo}
            history={this.history}
            modele={this.state.modele}
            modeleSrv={this.state.modeleSrv}
            appState={this.state.appState}
            methods={this.methods}
            onRouteHasChanged={this.handleRouteHasChanged}
            />;
        let header=<AppBar position="static" key="header">
              <Toolbar>
                <IconButton onClick={()=>this.toggleDrawer(true)} edge="start" className="app-menu-btn" color="inherit" aria-label="menu">
                  <MenuIcon/>
                </IconButton>
                <Drawer anchor="left" open={this.state.appState.drawerOpen} onClose={()=>this.toggleDrawer(false)}>
                <div className="menu">
                <List>
                    <Divider />
                    <ListItem button key="films" onClick={()=>this.goTo('/films/')}>
                        <ListItemText primary="Films" align="left"/>
                    </ListItem>
                    <ListItem button key="cinemas" onClick={()=>this.goTo('/cinemas/')}>
                        <ListItemText primary="Cinémas" align="left"/>
                    </ListItem>
                    <ListItem button key="seances" onClick={()=>this.goTo('/seances/')}>
                        <ListItemText primary="Séances" align="left"/>
                    </ListItem>
                </List>
                </div>
                </Drawer>
                <Typography variant="h6" className='app-bar-title'>
                  <span onClick={()=>this.goTo('/')}>E-cinéma</span>
                </Typography>
                <Button color="inherit" onClick={this.handleLogout}>Logout</Button>
              </Toolbar>
            </AppBar>
        app=[
            header,
            router
        ];
        let login=<form onSubmit={this.handleLogin}>
            <input type="password" onChange={this.handlePasswordChange}/>
            <input type="submit" value="se connecter"/>
        </form>;
        let res='';
        if (this.state.ready) {
            if (this.state.authorized) res=<MuiPickersUtilsProvider utils={MomentUtils}>
                {app}
            </MuiPickersUtilsProvider>;
            else res=login;
        }
        return res;
    }
}

export default App;
