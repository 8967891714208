import React, { Component } from 'react';
import _ from 'lodash';
import 'components/Films.scss';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import GetApp from '@material-ui/icons/GetApp';
import { ExportToCsv } from 'export-to-csv';


import Image from 'components/Image';
import Parametres from 'utils/Parametres';
import Utils from 'utils/Utils';

const options = {
    filename:'films',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

class Films extends Component {
    constructor(props){
        super(props);
        this.state={
            openNewFilmDialog:false,
            newFilmId:'',
            newFilmName:'',
            newFilmError:'',
        }
        this.handleDelete=this.handleDelete.bind(this);
        this.handleNewFilmIdChange=this.handleNewFilmIdChange.bind(this);
        this.handleNewFilmDialogOpen=this.handleNewFilmDialogOpen.bind(this);
        this.handleNewFilmDialogClose=this.handleNewFilmDialogClose.bind(this);
        this.handleCreateNewFilm=this.handleCreateNewFilm.bind(this);
        this.getCsv=this.getCsv.bind(this);
    }
    getCsv(){
        csvExporter.generateCsv(this.props.modele.films);
    }
    handleDelete(film){
        if(window.confirm('Est-vous sûr de vouloir supprimer ce film ?')) {
            this.props.methods.ajax({
                action:'del_film',
                film:film,
            });
        }
    }
    handleNewFilmIdChange(e){
        let name=e.target.value;
        let corrected=Utils.accentsTidy(name).toLowerCase().replace(/[^a-z0-9_]+/gi,'_');
        let error='';
        if (_.find(this.props.modele.films,{content_id:corrected})) error='Il y a déjà un film avec ce nom.';
        this.setState({
            newFilmId:corrected,
            newFilmName:name,
            newFilmError:error
        });
    }
    handleNewFilmDialogOpen(){
        this.setState({openNewFilmDialog:true});
    }
    handleNewFilmDialogClose(e){
        this.setState({openNewFilmDialog:false});
    }
    handleCreateNewFilm(e){
        e.preventDefault();
        this.setState({openNewFilmDialog:false});
        this.props.methods.ajax(
            {
                action:'add_film',
                film:{
                    content_id:this.state.newFilmId,
                    content_name:this.state.newFilmName,
                }
            },
            {},
            ()=>this.props.goTo('/film/'+this.state.newFilmId+'/')
        );
    }
    render() {
        let films=this.props.modele.films;
        return <Container maxWidth="lg" className="accueil">
        <Typography variant="h1" component="h1">
            Films &nbsp;
            <Fab color="primary" aria-label="add" onClick={this.handleNewFilmDialogOpen}>
                <AddIcon />
            </Fab> <Fab color="primary" aria-label="add" onClick={this.getCsv}>
                <GetApp />
            </Fab>
        </Typography>

        <Dialog open={this.state.openNewFilmDialog} onClose={this.handleNewFilmDialogClose}>
            <form onSubmit={this.handleCreateNewFilm}>
            <DialogTitle>Nouveau film</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Merci de saisir un titre pour ce nouveau film.
                </DialogContentText>
                <TextField
                autoFocus
                label="Titre"
                variant="filled"
                value={this.state.newFilmName}
                onChange={this.handleNewFilmIdChange}
                fullWidth
                />
                <p>{this.state.newFilmError}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleNewFilmDialogClose} color="primary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={this.state.newFilmId==='' || this.state.newFilmError!==''}>
                Ajouter
                </Button>
            </DialogActions>
            </form>
        </Dialog>
        <div className="spacer"></div>
        <Grid container spacing={3}>
        {films.map((film)=>{
            let poster=''
            let posterFile='img/cinema/poster/'+film.content_id+'.jpg';
            let posterMinFile='img/cinema/poster/min/'+film.content_id+'.jpg?'+this.props.appState.posterHash;
            let posterSrc=Parametres.ajaxUrl+'/'+posterMinFile;
            if (this.props.modele.posters.indexOf(posterFile)!==-1) poster=<div className="film-poster">
                <div className="film-poster-inner">
                    <Image src={posterSrc}/>
                </div>
            </div>;
            else poster=<div className="film-poster">
                <div className="film-poster-inner">
                </div>
            </div>;
            let shows=_.filter(this.props.modele.shows,film);
            return (<Grid item xs={6} md={4} lg={3} key={film.content_id}>
                <Card className="card">
                    <CardContent>
                        <Typography variant="h5" component="h2" noWrap>
                        {film.content_name || '(sans titre)'}
                        </Typography>
                    </CardContent>
                    {poster}
                    <CardActions>
                        <Button variant="contained" size="small" onClick={()=>this.props.goTo('/film/'+film.content_id+'/')}>modifier</Button>
                        <Button variant="contained" size="small" onClick={()=>this.handleDelete(film)} disabled={shows.length>0}>supprimer</Button>
                    </CardActions>
                </Card>
            </Grid>);
        })}
        </Grid>
        </Container>;
    }
}
export default Films;
