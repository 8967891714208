import React, { Component } from 'react';
import * as moment from 'moment';
import _ from 'lodash';
import 'index.css';
import 'components/Cinema.scss';
import { Path } from 'path-parser';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from "@material-ui/pickers";
import DialogActions from '@material-ui/core/DialogActions';

const path = new Path('/film/:content_id');

class AddSeance extends Component {
    constructor(props){
        super(props);
        this.params=path.test(props.data.url);
        this.state={
            newCinemaId: this.props.cinemaId || this.props.modele.cinemas[0].cinema_id,
            newFilmId: this.props.contentId || this.props.modele.films[0].content_id,
            newDate: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
        }
        this.handleNewCinemaIdChange=this.handleNewCinemaIdChange.bind(this);
        this.handleNewFilmIdChange=this.handleNewFilmIdChange.bind(this);
        this.handleNewDateChange=this.handleNewDateChange.bind(this);
        this.handleCreateNewSeance=this.handleCreateNewSeance.bind(this);
    }
    handleNewCinemaIdChange(e,v){
        let cinema_id;
        if (v) {
            cinema_id=v.cinema_id;
            this.setState({
                newCinemaId:cinema_id,
            });
        }
    }
    handleNewFilmIdChange(e,v){
        let content_id;
        if (v) {
            content_id=v.content_id;
            this.setState({
                newFilmId:content_id,
            });
        }
    }
    handleNewDateChange(e){
        let start_time=moment(e._d).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            newDate:start_time,
        });
    }
    handleCreateNewSeance(e){
        e.preventDefault();
        this.setState({openNewSeanceDialog:false});
        let cinema=_.find(this.props.modele.cinemas,{cinema_id:this.state.newCinemaId});
        let film=_.find(this.props.modele.films,{content_id:this.state.newFilmId});
        this.props.methods.ajax(
            {
                action:'add_show',
                show:{
                    cinema_id:cinema.cinema_id,
                    cinema_name:cinema.cinema_name,
                    city_name:cinema.city_name,
                    latitude:cinema.latitude,
                    longitude:cinema.longitude,
                    distance:cinema.distance,
                    content_id:film.content_id,
                    content_name:film.content_name,
                    teaser_id:film.teaser_id,
                    start_time:this.state.newDate,
                }
            },
            {},
            (res)=>this.props.goTo('/seance/'+res.last_id+'/')
        );
    }
    render() {
        let newFilm=_.find(this.props.modele.films,{content_id:this.state.newFilmId});
        let newCinema=_.find(this.props.modele.cinemas,{cinema_id:this.state.newCinemaId});
        return <Dialog open={this.props.open} onClose={this.props.onClose}>
            <form onSubmit={this.handleCreateNewSeance}>
            <DialogTitle>Nouvelle séance</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Merci de saisir un nom et une ville pour ce nouveau seance.
                </DialogContentText>
                <Autocomplete
                value={newCinema}
                onChange={this.handleNewCinemaIdChange}
                options={this.props.modele.cinemas}
                getOptionLabel={(option) => (option.cinema_name || option.cinema_id) +' / '+ option.city_name }
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} variant="filled" label="Cinéma" className="text-input"/>}
                />
                <Autocomplete
                value={newFilm}
                onChange={this.handleNewFilmIdChange}
                options={this.props.modele.films}
                getOptionLabel={(option) => option.content_name || option.content_id }
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} variant="filled" label="Film" className="text-input"/>}
                />
                <DateTimePicker
                    autoOk
                    inputVariant='filled'
                    ampm={false}
                    value={this.state.newDate}
                    onChange={this.handleNewDateChange}
                    allowKeyboardControl={true}
                    label="Date et heure"
                    leftArrowButtonProps={{ "aria-label": "Mois précédent" }}
                    rightArrowButtonProps={{ "aria-label": "Mois suivant" }}
                    className="text-input"
                />
                <p>{this.state.newSeanceError}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                Annuler
                </Button>
                <Button type="submit" color="primary">
                Ajouter
                </Button>
            </DialogActions>
            </form>
            </Dialog>;
    }
}
export default AddSeance;
